<!--
 * @Author: wjc
 * @Date: 2023-11-06 09:34:57
 * @LastEditors: wjc
 * @LastEditTime: 2024-04-09 15:14:09
 * @Description: 
-->
<template>
  <div class="menu-container bg-bg-primary h-50px color-white lt-xs:h-80px">
    <div class="screen-menu-trigger hidden-sm-and-up">
      <div class="flex items-center mx-16px h-full hover:text-link-color">
        <span
          :class="[open ? 'i-ep-close' : 'i-ant-design-menu-outlined']"
          class="wh-24px color-white cursor-pointer"
          @click="$emit('toggle-screen')"
        ></span>
        <MLink to="/" class="relative ma-x left--16px">
          <img src="/logo.png" class="wh-34px" />
        </MLink>
      </div>
    </div>
    <MRow justify="space-between" align="middle" class="default-menu h-full hidden-sm-and-down">
      <el-col class="flex! justify-around font-s-1.6rem items-center">
        <template v-for="item in groupList" :key="item.id">
          <template v-if="item.children && item.children.length > 0">
            <div class="flyout">
              <div
                :class="[optionStore.currentMenu === item.id ? 'flyout-button-active' : '']"
                class="flyout-button"
              >
                <span class="flyout-button-text">{{ item.name }}</span>
                <div class="flyout-menu">
                  <div>
                    <div v-for="child in item.children" :key="child.id">
                      <MLink :to="child.to || `/content/${child.id}`" class="menu">
                        {{ child.name }}
                      </MLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div v-else class="text">
            <MLink :to="item.to">{{ item.name }}</MLink>
          </div>
        </template>
      </el-col>
    </MRow>
  </div>
</template>

<script setup lang="ts">
  import { useOptionStore } from '~/stores/modules/option'
  import { getInfoLevel } from '~/api/activity/info'
  import type { IInfoLevel } from '~/models/activity'

  defineOptions({
    name: 'DefaultMenu',
  })

  defineProps<{
    open: boolean
  }>()
  defineEmits<{
    (e: 'toggle-screen'): void
  }>()

  const $screen = useScreen()
  const optionStore = useOptionStore()
  const groupList = computed<IInfoLevel[]>(() => optionStore.menus)
  const childrenGroupList = shallowRef<IInfoLevel[]>([])
  const groupIdFirst = ref('')
  const groupIdSecond = ref('')

  getInfoLevel().then((res) => {
    if (res.data && Array.isArray(res.data)) {
      const menuList = [
        {
          name: '首页',
          id: 'index',
          to: '/',
        },
        ...res.data,
        {
          name: '关于协会',
          id: 'about',
          children: [
            {
              name: '关于协会',
              to: '/about',
            },
            {
              name: '联系我们',
              id: 'contactUs',
              to: '/about/contactUs',
            },
            {
              name: '协会领导',
              id: 'associationLeadership',
              to: '/about/associationLeadership',
            },
            {
              name: '主要职责',
              id: 'primaryResponsibilities',
              to: '/about/primaryResponsibilities',
            },
          ],
        },
      ]
      optionStore.menus = menuList
      childrenGroupList.value = groupList.value[0]?.children || []
      groupIdFirst.value = groupList.value[0]?.id || ''
      groupIdSecond.value = childrenGroupList.value[0]?.id || ''
    }
  })
</script>

<style scoped lang="scss">
  $duration: 0.8s;

  .menu-container {
    @apply relative;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    .screen-menu-trigger {
      @apply relative w-full h-80px fixed top-0 z-100 bg-bg-primary;
    }
    .text {
      @apply flex-center h-full font-s-1.4rem color-white;
      &:hover {
        .m-link {
          @apply color-white;
        }
      }
    }
    .flyout {
      @apply relative cursor-pointer h-full;

      &:hover {
        @apply text-primary-color;
        transition: color 0.25s;
        .flyout-button {
          @apply relative;
          background: var(--primary-color-2);
          transition: background-color 0.25s;
          &::after {
            @apply absolute left-0 bottom-0 h-4px w-full inline-block bg-white;
            content: '';
          }
        }
        .flyout-menu {
          @apply py-12px;
          grid-template-rows: 1fr;
        }
      }
      .flyout-button {
        @apply flex items-center h-50px text-color-1 py-0 px-6px;
        transition: color 0.5s;

        .flyout-button-text {
          @apply flex items-center font-s-1.4rem color-white;
          transition: color 0.25s;
        }
      }
      .flyout-button-active {
        @apply relative;
        background-color: transparent;
        background-color: rgba(0, 0, 0, 0.1);
        transition: background-color 0.25s;

        &::after {
          @apply absolute left-0 bottom-0 h-4px w-full inline-block bg-white;
          content: '';
        }
      }
      .flyout-button-active {
        .flyout-button-text {
          @apply text-link-color font-500;
        }
      }
      .flyout-menu {
        @apply absolute top-51px right-0 bg-bg-overlay rounded-4px z-100;
        box-shadow: 0 12px 32px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.1);
        display: grid;
        grid-template-rows: 0fr;
        grid-template-columns: max-content;
        transition: 0.3s;
        overflow: hidden;
        div {
          @apply min-h-0;
          .menu {
            @apply relative inline-block w-full py-4px px-18px font-s-1.4rem font-400 text-color-1;
            transition: color $duration, opacity 0.3s, visibility 0.3s;
            &:hover {
              @apply text-primary-color;
            }
          }
        }
      }
    }
  }
</style>
